export const actionTypes = {
  GET_SMS: 'GET_SMS',
  GET_SMS_SUCCESS: 'GET_SMS_SUCCESS',
  GET_SMS_REQUEST: 'GET_SMS_REQUEST',
  GET_SMS_ERROR: 'GET_SMS_ERROR',
  CHECK_SMS: 'CHECK_SMS',
  CHECK_SMS_SUCCESS: 'CHECK_SMS_SUCCESS',
  CHECK_SMS_REQUEST: 'CHECK_SMS_REQUEST',
  CHECK_SMS_ERROR: 'CHECK_SMS_ERROR',
};

export const getSms = (phone) => ({ type: actionTypes.GET_SMS, phone });

export const getSmsSuccess = (message) => ({ type: actionTypes.GET_SMS_SUCCESS, message });

export const getSmsRequest = () => ({ type: actionTypes.GET_SMS_REQUEST });

export const getSmsError = (error) => ({ type: actionTypes.GET_SMS_ERROR, error });

export const checkSms = (phone, code) => ({ type: actionTypes.CHECK_SMS, phone, code });

export const checkSmsSuccess = (message, authKey) => ({ type: actionTypes.CHECK_SMS_SUCCESS, message, authKey });

export const checkSmsRequest = () => ({ type: actionTypes.CHECK_SMS_REQUEST });

export const checkSmsError = (error) => ({ type: actionTypes.CHECK_SMS_ERROR, error });
