export default {
  pikApi: 'https://api.strana.com',
  // pikApi: 'http://api.pik.ru.borisovrs.dev5.pikweb.net',
  pikApiSocket: 'https://auction.ws2.pikweb.net',
  pikStrapi: 'https://strapi.pik.ru',
  pikChatApi: 'https://chat-digital.pik.ru',
  requestProxy: 'https://proxyapi.pik.ru',
  pikSvg: '',
  uploadApi: 'https://upload.pik.ru',
};
