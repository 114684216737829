import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';

import { Icons, IconsPack, Typography } from '@pik/pik-ui';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Link from '../../../Link';
import bindClickOutside from '../../../../common/clickOutside';

import {
  Container,
  ActiveLink,
  ActiveCurrentLink,
  ActiveCurrentLinkIcon,
  ActiveLinkText,
  LinksContainer,
  Menu,
  MenuList,
  MenuItem,
  GlobalBlackoutStyle,
} from './styles';

const routes = require('../../../../server/routes');

const CLIENT_BUY_URL = '/client/buy';

class MobileNavBar extends Component {
  static propTypes = {
    mobileLinks: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    })).isRequired,
    isHidden: PropTypes.bool.isRequired,
    router: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  state = {
    open: false,
  };

  componentDidMount() {
    this.unbindClickOutside = bindClickOutside(this.hideModal, this.menu);
  }

  componentDidUpdate(prevProps) {
    this.checkScroll();

    if (prevProps.router.pathname !== this.props.router.pathname) {
      this.hideModal();
    }
  }

  componentWillUnmount() {
    this.unbindClickOutside();
  }

  hideModal = () => this.setState({ open: false });

  handleClick = () => this.setState(({ open }) => ({ open: !open }));

  getActiveCurrentLink = () => {
    const { router } = this.props;
    const rootPath = '/client';
    const slug = `${rootPath}/${router.pathname.split('/')[2]}`;
    const currentLink = routes[rootPath].links[slug];
    if (currentLink) {
      const { name, icon } = currentLink;
      return (
        <ActiveCurrentLink>
          <ActiveCurrentLinkIcon>
            <IconsPack name={icon} type="outline" size="m" />
          </ActiveCurrentLinkIcon>
          <Typography
            type="interface1"
            weight="meduim"
            dangerouslySetInnerHTML={name}
            equalLineHeight
          />
        </ActiveCurrentLink>
      );
    }
    return null;
  };

  checkScroll() {
    if (!this.state.open) {
      enableBodyScroll();
      return;
    }

    disableBodyScroll();
  }

  getCurrentActive = (url) => this.props.router.pathname.includes(url);

  renderCurrentLink = () => (
    <ActiveLink
      open={this.state.open}
    >
      <ActiveLinkText onClick={this.handleClick}>
        {this.getActiveCurrentLink()}
        <Icons className="arrowDown" size="s" type="arrowDown" />
      </ActiveLinkText>
    </ActiveLink>
  );

  renderLink = ({ name, url }) => (
    <MenuItem key={url} active={this.getCurrentActive(url)}>
      <Link href={url}>{name}</Link>
    </MenuItem>
  );

  renderLinks = () => {
    const { open } = this.state;
    const { links } = this.props;

    return (
      <LinksContainer open={open}>
        <Menu open={open}>
          <MenuList>
            {links.map((this.renderLink))}
          </MenuList>
        </Menu>
      </LinksContainer>
    );
  };

  render() {
    const { router: { pathname }, isHidden } = this.props;
    const { open } = this.state;
    const hideOnScroll = pathname && pathname.includes(CLIENT_BUY_URL);
    return (
      <Container hideOnScroll={hideOnScroll} isHidden={isHidden}>
        <div ref={(el) => this.menu = el}>
          {this.renderCurrentLink()}
          {this.renderLinks()}
          <GlobalBlackoutStyle open={open} />
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ menuService }) => ({
  isHidden: menuService.isHidden,
});

export default withRouter(connect(mapStateToProps)(MobileNavBar));
