import { actionTypes } from './actions';
import { screenStates } from './constants';

const initialState = {
  screenType: null,
  showModal: false,
  userAgent: null,
  screenState: screenStates.AUTH,
  showChatFaqModal: false,
  showChat: false,
};

const handleChangeScreenType = (state, action) => ({
  ...state,
  screenType: action.screenType,
});

const handleChangeShowModal = (state) => ({
  ...state,
  showModal: true,
});

const handleChangeHideModal = (state) => ({
  ...state,
  showModal: false,
});

const handleSetUserAgent = (state, { userAgent }) => ({
  ...state,
  userAgent,
});

const handleShowChatFaqModal = (state, { data }) => ({
  ...state,
  showChatFaqModal: data,
});

const handleShowChat = (state, { data }) => ({
  ...state,
  showChat: data,
});

const handleChangeScreenState = (state, action) => ({
  ...state,
  screenState: action.screenState,
});

export default (state = initialState, action) => {
  const handlers = {
    [actionTypes.CHANGE_SCREEN_TYPE]: handleChangeScreenType,
    [actionTypes.SHOW_MODAL]: handleChangeShowModal,
    [actionTypes.HIDE_MODAL]: handleChangeHideModal,
    [actionTypes.SET_USER_AGENT]: handleSetUserAgent,
    [actionTypes.SHOW_CHAT_FAQ_MODAL]: handleShowChatFaqModal,
    [actionTypes.CHANGE_SCREEN_STATE]: handleChangeScreenState,
    [actionTypes.SHOW_CHAT]: handleShowChat,
  };

  return handlers[action.type]
    ? handlers[action.type](state, action)
    : state;
};
