module.exports = {
  '/client': {
    redirect: '/client/property',

    links: {
      '/client/property': {
        name: 'Мои объекты',
        icon: 'house',
      },
      // '/client/buy': {
      //   name: 'Покупка',
      //   icon: 'price',
      // },
      // '/client/favourite': {
      //   name: 'Избранное',
      //   icon: 'favorites',
      // },
      // '/client/comparison': {
      //   name: 'Сравнение',
      //   icon: 'compare',
      // },
      '/client/mortgage': {
        name: 'Ипотека',
        icon: 'mortgage',
      },
      '/client/booking': {
        name: 'Бронирования',
        icon: 'cart',
      },
      // '/client/meeting': {
      //   name: 'Сопровождение',
      //   icon: 'checklist',
      // },
      // '/client/services': {
      //   name: 'Полезные сервисы',
      //   icon: 'additionalServices',
      //
      //   links: {
      //     '/client/services/appraisal': {},
      //     '/client/services/measure': {},
      //   },
      // },
      '/client/documents': {
        name: 'Документы',
        icon: 'document',
      },
      '/client/eds': {
        name: 'Электронная подпись',
        icon: 'eds',

        links: {
          '/client/eds/list': {},
          '/client/eds/signing': {},
        },
      },
      '/client/profile': {
        name: 'Профиль',
        icon: 'profile',
      },
    },
  },
  '/pages/email-confirmation': {},
  '/auth': {},
  '/': {
    redirect: '/client/property',
  } ,
  '/404': {
    redirect: '/client/property',
  } ,
};
