import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ChatWidget from '@pik/pik-chat-widget';
import { api as apiConstructor } from '@pik/pik-utils';
import disableScrollCssClass from '../../common/disableScrollCssClass';
import apiServices from '../../common/apiServices';
import { showChatFaqModal as showChatFaqModalAction } from '../../services/app/actions';
import { faqTypes, faqConditions } from '../../common/chatFaqConstants';
import { getBookingFullStage } from '../../services/booking/actions';

const api = apiConstructor(apiServices);

const createFaqCondition = (data) => {
  if (data && data.parameters && data.dataFromServer) {
    const { paymentType } = data.parameters;
    const checkSaleScheme = data.dataFromServer.flat && data.dataFromServer.flat.saleScheme;
    const saleScheme = checkSaleScheme ? data.dataFromServer.flat.saleScheme : undefined;

    return {
      paymentType: faqConditions[paymentType],
      saleScheme,
    };
  }

  return undefined;
};

const handleShowChatModal = (showChatModalAction, currentShowChatModal, showChatModal) => {
  if (currentShowChatModal !== showChatModal) {
    showChatModalAction(showChatModal);
  }
};

const handleShowChat = (showChatAction, currentShowChat, showChat) => {
  if (currentShowChat !== showChat) {
    showChatAction(showChat);
  }
};

const handleDateChristmas = (date) => {
  if (date) {
    const from = new Date(2019, 11, 31, 21, 0, 0);
    const to = new Date(2020, 0, 2, 14, 0, 0);
    const fromDate = date.getTime() >= from.getTime();
    const toDate = date.getTime() <= to.getTime();
    if (fromDate && toDate) {
      return true;
    }
  }
  return false;
};

const createCustomUserData = (fullStage) => {
  if (fullStage && fullStage.name) {
    const { name, title } = fullStage;
    return { stageName: name, stageTitle: title };
  }

  return null;
};

const Chat = ({
  customer, zIndex, data, fullStage, showChatFaqModal, screenType,
  showChatFaqModalAction, getBookingFullStageAction, showChat: showChatProps,
}) => {
  const [showChat, showChatAction] = useState(false);
  const [showChatModal, showChatModalAction] = useState(false);
  const isMobile = screenType === 'mobile';

  useEffect(() => {
    if (showChatModal) {
      setTimeout(() => disableScrollCssClass.on(), 500);
    } else {
      setTimeout(() => disableScrollCssClass.off(), 500);
    }
  }, [showChatModal]);

  if (handleDateChristmas(new Date())) { return null; }

  const stage = data && data.stage ? data.stage : undefined;
  const loading = data && 'loading' in data ? data.loading : undefined;
  const step = data && 'step' in data ? data.step : undefined;
  const saleScheme = data && data.dataFromServer && data.dataFromServer.flat
    ? data.dataFromServer.flat.saleScheme : undefined;
  const checkDduDkp = saleScheme && ['ddu', 'dkp'].indexOf(saleScheme) !== -1;
  const isFaq = checkDduDkp && stage && !!Object.keys(faqTypes).find((key) => key === stage);
  const faqType = faqTypes[stage] ? faqTypes[stage] : undefined;
  const faqCondition = createFaqCondition(data);
  const checkZIndex = isMobile ? !showChat : !showChatFaqModal;
  const withZIndex = zIndex ? checkZIndex : false;
  const customUserData = createCustomUserData(fullStage);

  useEffect(() => {
    if (data) {
      getBookingFullStageAction();
    }
  }, [stage, loading, step]);

  if (showChatProps) {
    return (
      <ChatWidget
        otherLK='strana'
        zIndex={withZIndex && '1500'}
        api={api}
        customer={customer}
        showChat={showChat}
        handleShowChat={(value) => handleShowChat(showChatAction, showChat, value)}
        handleShowChatModal={(value) => handleShowChatModal(showChatModalAction, showChatModal, value)}
        handleShowFaqModal={(value) => showChatFaqModalAction(value)}
        isFaq={isFaq}
        // faqType={faqType}
        faqCondition={faqCondition}
        customUserData={customUserData}
      />
    );
  }

  return null;
};

Chat.propTypes = {
  customer: PropTypes.shape({}),
  data: PropTypes.shape({
    stage: PropTypes.string,
    step: PropTypes.string,
    loading: PropTypes.bool,
    dataFromServer: PropTypes.shape({
      flat: PropTypes.shape({
        saleScheme: PropTypes.string,
      }),
    }),
  }),
  zIndex: PropTypes.bool,
  showChatFaqModal: PropTypes.bool.isRequired,
  showChatFaqModalAction: PropTypes.func.isRequired,
  showChat: PropTypes.bool,
};

Chat.defaultProps = {
  customer: null,
  data: null,
  zIndex: false,
  showChat: false,
};

function mapStateToProps(state) {
  const {
    customerService: { customer },
    appService: { screenType, showChatFaqModal, showChat },
    bookingService: { data, fullStage },
  } = state;
  return {
    showChatFaqModal,
    customer,
    screenType,
    data,
    fullStage,
    showChat,
  };
}

const mapDispatchToProps = {
  showChatFaqModalAction,
  getBookingFullStageAction: getBookingFullStage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
